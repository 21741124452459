// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {
	Array.prototype.forEach = function (callback, thisArg) {

		var T, k;

		if (this == null) {
			throw new TypeError(' this is null or not defined');
		}

		// 1. Let O be the result of calling ToObject passing the |this| value as the argument.
		var O = Object(this);

		// 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
		// 3. Let len be ToUint32(lenValue).
		var len = O.length >>> 0;

		// 4. If IsCallable(callback) is false, throw a TypeError exception.
		// See: http://es5.github.com/#x9.11
		if (typeof callback !== "function") {
			throw new TypeError(callback + ' is not a function');
		}

		// 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
		if (arguments.length > 1) {
			T = thisArg;
		}

		// 6. Let k be 0
		k = 0;

		// 7. Repeat, while k < len
		while (k < len) {

			var kValue;

			// a. Let Pk be ToString(k).
			//   This is implicit for LHS operands of the in operator
			// b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
			//   This step can be combined with c
			// c. If kPresent is true, then
			if (k in O) {

				// i. Let kValue be the result of calling the Get internal method of O with argument Pk.
				kValue = O[k];

				// ii. Call the Call internal method of callback with T as the this value and
				// argument list containing kValue, k, and O.
				callback.call(T, kValue, k, O);
			}
			// d. Increase k by 1.
			k++;
		}
		// 8. return undefined
	};
}
if (!String.prototype.trim) {
	String.prototype.trim = function () {
		return this.replace(/^\s+|\s+$/g, '');
	};
}
if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function (searchElement, fromIndex) {

		var k;

		// 1. Let O be the result of calling ToObject passing
		//    the this value as the argument.
		if (this == null) {
			throw new TypeError('"this" is null or not defined');
		}

		var O = Object(this);

		// 2. Let lenValue be the result of calling the Get
		//    internal method of O with the argument "length".
		// 3. Let len be ToUint32(lenValue).
		var len = O.length >>> 0;

		// 4. If len is 0, return -1.
		if (len === 0) {
			return -1;
		}

		// 5. If argument fromIndex was passed let n be
		//    ToInteger(fromIndex); else let n be 0.
		var n = +fromIndex || 0;

		if (Math.abs(n) === Infinity) {
			n = 0;
		}

		// 6. If n >= len, return -1.
		if (n >= len) {
			return -1;
		}

		// 7. If n >= 0, then Let k be n.
		// 8. Else, n<0, Let k be len - abs(n).
		//    If k is less than 0, then let k be 0.
		k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

		// 9. Repeat, while k < len
		while (k < len) {
			// a. Let Pk be ToString(k).
			//   This is implicit for LHS operands of the in operator
			// b. Let kPresent be the result of calling the
			//    HasProperty internal method of O with argument Pk.
			//   This step can be combined with c
			// c. If kPresent is true, then
			//    i.  Let elementK be the result of calling the Get
			//        internal method of O with the argument ToString(k).
			//   ii.  Let same be the result of applying the
			//        Strict Equality Comparison Algorithm to
			//        searchElement and elementK.
			//  iii.  If same is true, return k.
			if (k in O && O[k] === searchElement) {
				return k;
			}
			k++;
		}
		return -1;
	};
}
if (!String.prototype.startsWith) {
	String.prototype.startsWith = function (searchString, position) {
		position = position || 0;
		return this.lastIndexOf(searchString, position) === position;
	};
}
if (!String.prototype.left) {
	String.prototype.left = function (length) {
		length = length || 0;
		if (length > this.length)
			return this;
		return this.substr(0, length);
	};
}
$.fn.changeVal = function (v) {
	return $(this).val(v).trigger("change");
};	
// modified from the url http://davidwalsh.name/pubsub-javascript

var pubsub;
(function (exports) {
	'use strict';
	var topics = {};
	var hOP = topics.hasOwnProperty;

	var subscribe = function (topic, listener) {
		// Create the topic's object if not yet created
		if (!hOP.call(topics, topic)) topics[topic] = [];

		// Add the listener to queue
		var index = topics[topic].push(listener) - 1;

		// Provide handle back for removal of topic
		return {
			remove: function () {
				delete topics[topic][index];
			}
		};
	};

	var publish = function (topic, data) {
		// If the topic doesn't exist, or there's no listeners in queue, just leave
		if (!hOP.call(topics, topic)) return;

		// Cycle through topics queue, fire!
		for (var i = 0; i < topics[topic].length; i++)
		{
			var item = topics[topic][i];
			item((data != undefined) ? data : {});
		}
	};

	var pubsub = {
		subscribe: subscribe,
		publish: publish
	}

	exports.pubsub = pubsub;
})(this);
var BisibiConfig = (function (config) {
	var styleAssets = {};
	var scriptAssets = {};
	var viewModel = {};

	var getStyleAssets = function () {
		return styleAssets;
	};
	var setStyleAssets = function (assets) {
		styleAssets = extend(styleAssets, assets);
	};

	var getScriptAssets = function () {		
		return scriptAssets;
	};
	var setScriptAssets = function (assets) {
		scriptAssets = extend(scriptAssets, assets);
	};

	var getViewModel = function () {
		return viewModel;
	};
	var setViewModel = function (model) {
		viewModel = model;
	};

	var extend = function () {
		// Variables
		var extended = {};
		var deep = false;
		var i = 0;
		var length = arguments.length;

		// Check if a deep merge
		if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
			deep = arguments[0];
			i++;
		}

		// Merge the object into the extended object
		var merge = function (obj) {
			for (var prop in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, prop)) {
					// If deep merge and property is an object, merge properties
					if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
						extended[prop] = extend(true, extended[prop], obj[prop]);
					} else {
						extended[prop] = obj[prop];
					}
				}
			}
		};

		// Loop through each object and conduct a merge
		for (; i < length; i++) {
			var obj = arguments[i];
			merge(obj);
		}

		return extended;
	};

	return {
		getStyleAssets: getStyleAssets,
		setStyleAssets: setStyleAssets,
		getScriptAssets: getScriptAssets,
		setScriptAssets: setScriptAssets,
		getViewModel: getViewModel,
		setViewModel: setViewModel
	};
}());